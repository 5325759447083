<template>
  <div class="dashboard-ecommerce">
    <div class="container-fluid dashboard-content">
      <x-loading v-if="isLoading" />
      <!-- ============================================================== -->
      <!-- pageheader  -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="page-header">
            <h2 class="pageheader-title">UTILISATEURS</h2>
            <p class="pageheader-text"></p>

            <div class="page-breadcrumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#/home" class="breadcrumb-link">TABLEAU DE BORD</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">UTILISATEURS</li>
                  <a
                    href="javascript:void(0)"
                    class="btn btn-secondary"
                    @click="openUserAddModal"
                    style="position: absolute; top: -8px; right: 10px"
                    ><i class="fas fa-plus font-10"></i> Nouveau utilisateur</a
                  >
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- end pageheader  -->
      <!-- ============================================================== -->
      <div class="ecommerce-widget">
        <div class="row">
          <div
            class="col-xl-4 col-lg-6 col-md-6 col-sm-12 col-12"
            v-for="admin in admins"
            :key="admin.admin_id"
          >
            <div class="card">
              <div class="card-header">
                <i class="far fa-user text-success"></i>
              </div>
              <div class="card-body">
                <h2>{{admin.nom}}</h2>
                <span>administrateur</span>
              </div>
              <div class="card-footer p-0 text-center d-flex justify-content-center">
                <div class="card-footer-item card-footer-item-bordered">
                  <a href="#" class="card-link" @click="openAttributeAccessModal"
                    >Voir accès</a
                  >
                </div>
                <div class="card-footer-item card-footer-item-bordered">
                  <a href="#" class="card-link" @click="openAttributeAccessModal"
                    >Attribuer accès</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <g-modal
      :min-width="200"
      :min-height="200"
      :scrollable="true"
      :reset="true"
      width="30%"
      height="auto"
      transition="pop-out"
      name="attribute-access-modal"
    >
      <div class="card">
        <h5 class="card-header">Attribution accès</h5>
        <div class="card-body p-0">
          <ul class="traffic-sales list-group list-group-flush">
            <li class="traffic-sales-content list-group-item" v-for="i in 6" :key="i">
              <label class="custom-control custom-checkbox cursor-pointer">
                <input type="checkbox" checked="" class="custom-control-input" /><span
                  class="custom-control-label"
                  >User access {{ i }}</span
                >
              </label>
            </li>
          </ul>
        </div>
      </div>
    </g-modal>

    <g-modal
      :min-width="200"
      :min-height="200"
      :scrollable="true"
      :reset="true"
      width="30%"
      height="auto"
      transition="pop-out"
      name="user-add-modal"
    >
      <div class="card">
        <div class="card-header">
          <h3 class="mb-1">CREATION NOUVEAU UTILISATEUR</h3>
        </div>
        <div class="card-body">
          <div class="form-group">
            <input
              class="form-control form-control-lg"
              type="text"
              name="nick"
              required=""
              placeholder="utilisateur nom"
              autocomplete="off"
              v-model="user.nom"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-lg"
              type="email"
              name="email"
              required=""
              placeholder="E-mail"
              autocomplete="off"
              v-model="user.email"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-lg"
              type="email"
              name="email"
              required=""
              placeholder="Téléphone"
              autocomplete="off"
              v-model="user.telephone"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-lg"
              id="pass1"
              type="password"
              required=""
              placeholder="Mot de passe"
              v-model="user.pass"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-lg"
              required=""
              placeholder="Confirmation mot de passe"
              v-model="user.confirmPass"
            />
          </div>
          <div class="form-group pt-2">
            <button class="btn btn-success m-r-10" @click.prevent="addUser">
              <i class="fas fa-plus font-10"></i> Créer nouveau utilisateur
            </button>
            <button class="btn btn-light" @click="hideUserAddModal">
              <i class="fas fa-ban font-10"></i> Annuler
            </button>
          </div>
        </div>
      </div>
    </g-modal>
  </div>
</template>

<script>
export default {
  data()
  {
    var user={
      nom:"",
      email:"",
      telephone:"",
      pass:"",
      confirmPass:""
    };
    return {
      user:user,
      isLoading:false
    };
  }
  ,
  computed:{
    admin()
    {
      return this.$store.getters.getAdmin;
    },
    admins()
    {
      return this.$store.state.admins;
    }
  },
  methods: {
    openAttributeAccessModal() {
      this.$modal.show("attribute-access-modal");
    },
    openUserAddModal() {
      this.$modal.show("user-add-modal");
    },
    hideUserAddModal() {
      this.$modal.hide("user-add-modal");
    },
    addUser()
    {
      var data=new FormData();
      data.append("admin_id",this.admin.admin_id)
      data.append("nom",this.user.nom);
      data.append("email",this.user.email);
      data.append("telephone",this.user.telephone);
      data.append("pass",this.user.pass);

      if(this.user.pass!==this.user.confirmPass)
      {
        this.$swal({
          text:"Les Mots de passe ne correspondent pas.",
          icon:"error",
          toast:true,
          timer:3000,
          showConfirmButton:false
        });
        return false;
      }

      this.isLoading=true;
      this.$store.dispatch("addUser",data).then((res)=>{
        this.isLoading=false;

        console.clear(); console.log(res);
        if(res.reponse!==undefined && res.reponse.status==="success")
        {
          this.hideUserAddModal();
          this.$swal({
            text:"Utilisateur ajouté !",
            icon:"success",
            toast:true,
            timer:3000,
            showConfirmButton:false
          });
        }
        else
        {
          this.$swal({
            text:res.error,
            icon:"error",
            toast:true,
            timer:3000,
            showConfirmButton:false
          });
        }
      });
    }
  },
  mounted()
  {
    var formData=new FormData();
    formData.append("admin_id",this.admin.admin_id);
    this.isLoading=true;
    this.$store.dispatch("getAdmins",formData).then((res)=>{
      this.isLoading=false;
      //console.clear(); console.log("Admins.."); console.log(res);
    });
  }

};
</script>
