import index from "@/components/index";
import dashboard from "@/components/pages/dashboard";
import Login from "@/components/pages/auth/login_auth.vue";

import DiligencesPage from "@/components/pages/diligences-page";
import DiligencesDetailPage from "@/components/pages/details/diligence_detail_page";
import UsersPage from "@/components/pages/users_view_page";

import cacheStore from "../store/cacheStore";
function auth(to,from,next)
{
    var admin=cacheStore.get("admin");

    if(admin!==undefined && Object.keys(admin).length>0)
    {
        next();
    }
    else
    {
        next({name:"login"});
    }
}


const routes = [{
        path: '/',
        component: Login,
        name: 'login'
    },
    {
        path: '/home',
        component: index,
        name: 'home',
        beforeEnter:auth,
        children: [{
                path: '',
                component: dashboard,
                name: 'dashboard'
            },
            {
                path: '/diligences',
                component: DiligencesPage,
                name: 'diligence-page'
            },
            {
                path: '/diligence/detail/:id',
                component: DiligencesDetailPage,
                name: 'diligence-detail',
            },
            {
                path: '/users',
                component: UsersPage,
                name: 'user-page'
            },


        ]
    },
];
export default routes;
