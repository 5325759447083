<template>
  <div class="dashboard-ecommerce">
    <x-loading v-if="isLoading" />
    <div class="container-fluid dashboard-content">
      <!-- ============================================================== -->
      <!-- pageheader  -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="page-header">
            <h2 class="pageheader-title">DILIGENCES</h2>
            <p class="pageheader-text"></p>
            <div class="page-breadcrumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#/diligences" class="breadcrumb-link"
                      ><i class="fas fa-arrow-left"></i
                    ></a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    DILIGENCE DETAIL
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- end pageheader  -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header p-4">
              <div class="float-left">
                <h3 class="text-dark">{{diligence.objet}}</h3>
                <span class="d-inline-block"
                  ><span class="text-primary font-bold"> TYPE : </span> {{diligence.diligence_type}}</span><br>
              </div>

              <div class="float-right"><span class="fa fa-user-circle"></span> {{diligence.client}} | <span class="fa fa-calendar-alt"></span> {{diligence.date_enregistrement}}</div>
              <br>
              <div class="float-right" style="margin-top:10px; border:1px solid lightgray; padding: 5px;">
                <button v-if="certificat===null" @click.prevent="genererCertificat" class="btn btn-danger pull-right"><span class="fa fa-book"></span> Certificat</button>
                <div v-else style="color: dodgerblue;">
                  <span><span class="fa fa-book"></span> Certificat | {{certificat.date_enregistrement}} </span><br>
                  <span><span class="fa fa-calendar-alt"></span> Expire: {{certificat.expire}}</span>
                  <hr>
                  <span class="fa fa-download"> {{certificat.total_downloads}}</span> |
                  <span class="fa fa-eye"> {{certificat.total_checking}}</span>
                </div>

              </div>
            </div>
            <div class="card-body">
              <div class="simple-card" style="border: none">
                <ul class="nav nav-tabs" id="myTab5" role="tablist">
                  <li class="nav-item">
                    <a
                            class="nav-link active border-left-0"
                            id="home-tab-simple"
                            data-toggle="tab"
                            href="#questionnaire"
                            role="tab"
                            aria-controls="home"
                            aria-selected="true"
                    >
                      <i class="fas fa-copy"></i> Questionnaire</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                            class="nav-link"
                            id="profile-tab-simple"
                            data-toggle="tab"
                            href="#profile-simple"
                            role="tab"
                            aria-controls="profile"
                            aria-selected="false"
                    ><i class="fas fa-file"></i> DOCUMENTS</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link border-left-0"
                      id="home-tab-simple"
                      data-toggle="tab"
                      href="#home-simple"
                      role="tab"
                      aria-controls="home"
                      aria-selected="true"
                    >
                      <i class="fas fa-copy"></i> ACTES</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent5">

                  <div
                          class="tab-pane fade show active"
                          id="questionnaire"
                          role="tabpanel"
                          aria-labelledby="home-tab-simple"
                  >
                    <p>Question ({{this.index+1}}/{{questions.length}})</p>
                    <hr>
                    <!-- ajouter information !-->
                    <div class="row">
                      <div class="col-md-10">

                      </div>
                      <div class="col-md-2">
                        <button @click.prevent="toggleAddInfo" class="btn btn-primary pull-right" style=""><span class="fa fa-plus-circle"></span> Ajouter</button>
                      </div>
                      <div v-if="addInfo" class="col-md-12" style="border:1px solid lightgray; margin: 5px; padding:10px;">
                        <p>Ajouter une information:</p>
                        <div class="form-group">
                          <input
                                  class="form-control form-control-lg"
                                  type="text"
                                  name="nick"
                                  required=""
                                  placeholder="Entrez l'information"
                                  autocomplete="off"
                                  v-model="newInfo"
                          />
                        </div>
                        <div class="form-group">
                          <label for="validationCustom01"
                          >Attacher un document <sup class="text-danger">*</sup></label
                          >
                          <input class="form-control form-control-lg" type="file" id="pieceJointe" />
                        </div>

                        <div class="form-group pt-2">
                          <button class="btn btn-primary m-r-10" @click.prevent="addNewInfo">
                            <i class="fas fa-plus font-10"></i> Enregistrer
                          </button>
                          <button @click.prevent="toggleAddInfo" style="" class="btn btn-light">
                            <i class="fas fa-ban font-10"></i> Annuler
                          </button>
                        </div>
                      </div>
                    </div>
                    <!-- ajouter information end !-->

                    <h3><span class="fa fa-question-circle"></span> Question: {{question.question}}</h3>
                    <p style=""><span class=""></span> Réponse: </p>
                    <div v-if="question.reponses[0].media!==undefined" style="width: 250px; border:1px solid lightgray; padding:10px;">
                      <img  :src="question.reponses[0].media" alt="" style="width: 100%; height:250px;">
                    </div>
                    <div v-else>
                      <p v-for="reponse in question.reponses" :key="reponse.diligence_questionnaire_id" style="text-transform: uppercase;"> - {{reponse.reponse}}</p>
                    </div>
                    <br>

                    <button v-if="index>0" class="btn btn-default" style="margin: 5px;" @click.prevent="viewPreviousQuestion"><span class="fa fa-arrow-left"></span></button>
                    <button v-if="index<questions.length-1" class="btn btn-default" @click.prevent="viewNextQuestion"><span class="fa fa-arrow-right"></span></button>



                  </div>

                  <div
                    class="tab-pane fade"
                    id="profile-simple"
                    role="tabpanel"
                    aria-labelledby="profile-tab-simple"
                  >
                    <div class="row">
                      <div
                        class="col-xl-3 col-lg-6 col-md-6 col-sm-12 col-12"
                        v-for="document in diligenceDetails.document_uploads"
                        :key="document.document_upload_id"
                      >
                        <!-- .card -->
                        <div
                          class="card card-figure has-hoverable"
                          style="cursor: pointer"
                        >
                          <!-- .card-figure -->
                          <figure class="figure">
                            <!-- .figure-img -->
                            <div class="figure-attachment">
                              <img :src="document.media" alt="" style="width:100%; height: 250px;">

                            </div>
                            <!-- /.figure-img -->
                            <figcaption class="figure-caption">
                              <ul class="list-inline d-flex text-muted mb-0">
                                <li class="list-inline-item text-truncate mr-auto">
                                  <p class="font-18 font-bold">{{document.designation}}</p>
                                  <p class="text-muted mb-0">
                                    <i class="fas fa-calendar-alt"></i> {{document.date_enregistrement}}
                                  </p>
                                </li>
                                <li class="list-inline-item">
                                  <a href="assets/images/card-img-1.jpg">
                                    <i class="fas fa-download"></i
                                  ></a>
                                </li>
                              </ul>
                            </figcaption>
                          </figure>
                          <!-- /.card-figure -->
                        </div>
                        <!-- /.card -->
                      </div>
                    </div>
                  </div>

                  <!-- tab actes !-->
                  <div
                          class="tab-pane fade"
                          id="home-simple"
                          role="tabpanel"
                          aria-labelledby="home-tab-simple"
                  >
                    <button class="btn btn-primary float-right" @click.prevent="toggleNewActe">
                      <span v-if="!newActe" class="fa fa-plus"></span>
                      <span v-else class="fa fa-window-close"></span>
                    </button>

                    <!-- section pour afficher les actes !-->
                    <div v-if="!newActe" class="row">
                      <div style="" class="col-md-3" v-for="acte in diligenceDetails.actes" :key="acte.diligence_acte_id">
                        <!-- .card -->
                        <div class="card card-figure has-hoverable">
                          <!-- .card-figure -->
                          <figure class="figure">
                            <!-- .figure-img -->
                            <div style=" height:100px;" class="figure-attachments">
                              <span  style="cursor: pointer;" class="fa-stack fa-lg">
                                <i class="fa fa-square fa-stack-2x text-success"></i>
                                <i v-if="acte.media!==undefined" class="fa fa-file fa-stack-1x fa-inverse"></i>
                              </span>
                              <h4 style="margin-top: 10px;">{{acte.acte}}</h4>
                            </div>

                            <!-- /.figure-img -->
                            <figcaption style=" " class="figure-caption">

                              <ul class="list-inline d-flex text-muted mb-0">
                                <li class="list-inline-item">
                                  <a href="" @click.prevent="">
                                    <i class="fas fa-user-circle"></i> {{acte.nom_admin}} <br><span class="fa fa-calendar-alt"></span> {{acte.date_enregistrement}}</a>
                                </li>
                              </ul>
                            </figcaption>
                          </figure>
                          <!-- /.card-figure -->
                        </div>
                        <!-- /.card -->
                      </div>
                    </div>

                    <!-- section pour saisir un acte !-->
                    <div v-else>
                      <div class="form-group">
                        <label for="validationCustom01"
                        >Acte <sup class="text-danger">*</sup></label
                        >
                        <input
                                class="form-control form-control-lg"
                                type="text"
                                name="nick"
                                required=""
                                placeholder="Entrez la designation de l'acte"
                                autocomplete="off"
                                v-model="acte.designation"
                        />
                      </div>
                      <div class="form-group">
                        <label for="validationCustom01"
                        >Pièces jointes <sup class="text-danger">*</sup></label
                        >
                        <input class="form-control form-control-lg" type="file" id="pieceJointe" />
                      </div>
                      <div class="form-group">
                        <label for="validationCustom01">Observation(optionnel)</label>
                        <textarea v-model="acte.observation" class="form-control form-control-lg"></textarea>
                      </div>

                      <div class="form-group pt-2">
                        <button class="btn btn-primary m-r-10" @click.prevent="noterActe">
                          <i class="fas fa-plus font-10"></i> Ajouter acte
                        </button>
                        <button style="display: none;" class="btn btn-light">
                          <i class="fas fa-ban font-10"></i> Annuler
                        </button>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            <div class="card-footer bg-white">
              <p class="mb-0">
                Status : <span class="badge badge-brand"> {{diligence.diligence_status}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- ============================================================== -->
  <!-- end wrapper  -->
  <!-- ============================================================== -->
</template>

<script>
  export default {
    data()
    {
      return {
        newActe:false,
        isLoading:true,
        diligenceId:0,
        acte:{designation:"",observation:""},
        question:{},
        index:0,
        addInfo:false,
        newInfo:"",
      }
    },
    methods:{
      toggleNewActe() /** method pour changer l'etat de newActe */
      {
        if(this.newActe)
        {
          this.newActe=false;
        }
        else
        {
          this.newActe=true;
        }
      },
      noterActe()
      {
        if(this.acte.designation.length<1)
        {
          this.$swal(
                  {
                    text:"Veuillez correctement saisir votre acte !",
                    icon:"error",
                    toast:true,
                    timer:3000,
                    showConfirmButton:false
                  });

          return false;
        }
        this.isLoading=true;

        var formData=new FormData();
        formData.append("admin_id",this.admin.admin_id);
        formData.append("diligence_id",this.diligenceId);
        formData.append("acte",this.acte.designation);
        /**
         * Piece jointe.
         * */
        var files=document.getElementById("pieceJointe").files;
        if(files.length>0)
        {
          var file=files[0];
        }
        formData.append("fichier",file);
        formData.append("observation",this.observation);

        this.$store.dispatch("enregistrerActe",formData).then((res)=>{
          this.isLoading=false;

          if(res.reponse!==undefined && res.reponse.status==="success")
          {
            console.clear(); console.log(res);
            var f=new FormData();
            f.append("admin_id",this.admin.admin_id);
            f.append("diligence_id",this.diligenceId);
            this.$store.dispatch("getDiligenceDetails",f);
            this.acte.designation="";
            this.acte.observation="";
            this.$swal(
                    {
                      text:"Acte enregistré avec succès !",
                      icon:"success",
                      toast:true,
                      timer:3000,
                      showConfirmButton:false
                    }
            );
          }
          else
          {
            this.$swal(
                    {
                      text:"Erreur système !",
                      icon:"success",
                      toast:true,
                      timer:3000,
                      showConfirmButton:false
                    }
            );
          }
        });
      },
      viewNextQuestion()
      {
        this.index=this.index+1;
        this.question=this.questions[this.index];
      },
      viewPreviousQuestion()
      {
        this.index=this.index-1;
        this.question=this.questions[this.index];
      },
      toggleAddInfo()
      {
        if(this.addInfo)
        {
          this.addInfo=false;
        }
        else
        {
          this.addInfo=true;
        }
      },
      addNewInfo() /** method pour ajouter une information */
      {
        if(this.newInfo.length<3)
        {
          this.$swal({
            title: "Saisissez correctement l'information.",
            icon:'error',
            timer:3000,
            toast:true,
            showConfirmButton:false
          });
          return false;
        }
        this.isLoading=true;
        var formData=new FormData();
        formData.append("admin_id",this.admin.admin_id);
        formData.append("diligence_id",this.diligence.diligence_id);
        formData.append("question_id",this.question.question_id);
        formData.append("reponse",this.newInfo);

        this.$store.dispatch("addNewInfo",formData).then((res)=>{

          this.toggleAddInfo();
          /**
           * refresh les données de la diligence au UI.
           * */
          this.refreshContent();

        });

      },
      refreshContent() /** method pour charger/refresh le contenu du UI */
      {
        this.isLoading=true;
        var formData=new FormData();
        formData.append("admin_id",this.admin.admin_id);
        formData.append("diligence_id",this.diligenceId);

        this.$store.dispatch("getDiligenceDetails",formData).then(()=>{
          this.isLoading=false;
          if(this.questions.length>0)
          {
            this.question=this.questions[this.index];
          }
        });
      },
      genererCertificat() /** method pour générer un certificat */
      {
        this.$swal({
          title:"Voulez-vous générer un certificat?",
          showConfirmButton:true,
          showCancelButton:true,
          confirmButtonText:"Oui",
          cancelButtonText:"Non",
          toast:true
        }).then((result)=>{
          if(!result.value)
          {
            return false;
          }

          this.isLoading=true;
          var formData=new FormData();
          formData.append("admin_id",this.admin.admin_id);
          formData.append("diligence_id",this.diligenceId);

          this.$store.dispatch("genererCertificat",formData).then((res)=>{
            console.clear(); console.log(res);
            if(res.reponse!==undefined && res.reponse.status==="success")
            {
              this.refreshContent();
              this.displayMsg("Vous venez de générer un certificat.");
            }
            else
            {
              this.displayMsg("Impossible de générer le certificat.",'error');
            }

            this.isLoading=false;

          });
        });
      },
      displayMsg(msg,icon="success")
      {
        this.$swal({
          title:msg,
          timer:3000,
          toast:true,
          showConfirmButton:false,
          icon:icon
        });
      }
    },
    computed:{
      diligence()
      {
        var diligenceId=this.$route.params.id;
        /**
         * Recuperer quelques données de cette diligence déjà presente dans le store
         */
        var diligences=this.$store.state.diligences;

        var diligence={};
        for(var i=0; i<diligences.length; i++)
        {
          if(diligences[i].diligence_id===diligenceId)
          {
            diligence=diligences[i];
            break;
          }
        }

        return diligence;
      },
      admin()
      {
        return this.$store.getters.getAdmin;
      },
      diligenceDetails()
      {
        return this.$store.state.diligenceDetails;
      },
      questions()
      {
        return this.$store.state.diligenceDetails.questionnaire;
      },
      certificat()
      {
        return this.$store.state.diligenceDetails.certificat;
      }

    },
    mounted()
    {

      this.diligenceId=this.$route.params.id;
      //TODO:télécharger une pièce jointe d'une acte.
      if(Object.keys(this.diligence).length<1)
      {
        this.$router.replace("/diligences/");
      }

      this.refreshContent();
    }


  }
</script>
