import axios from "axios";
import { scroller } from "vue-scrollto/src/scrollTo";

const actions = {
    getDiligences({state,commit},data) /** pour recuperer les diligences du serveur */
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/diligences/all",data).then(function(result)
            {
               var data=result.data;

               commit("setDiligences",data.diligences);
               resolve(data);
            });
        });
    },
    scrollToTop() {
        const scrollTo = scroller();
        scrollTo("#top");
    },
    getDiligenceDetails({state,commit},data) /** pour recuperer les details d'une diligence */
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/diligences/view",data).then(function(result)
            {
                var data=result.data;
                //console.clear(); console.log(data);

                resolve(data);

                var details=data.details;
                /*var diligences=state.diligences;
                for(var i=0; i<diligences.length; i++)
                {
                    if(diligences[i].diligence_id===details.document_uploads.diligence_id)
                    {
                        diligences[i].details=details;
                        break;
                    }
                }*/
                commit("setDiligenceDetails",details);
            });

        });

    },
    enregistrerActe({state},data)
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/diligences/actes",data).then(function(result)
            {
               var data=result.data;

               resolve(data);
            });
        });

    },
    addUser({state},data)
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/users/add",data).then(function(result)
            {
                var data=result.data;

                resolve(data);
            });
        });
    },
    login({state,commit},data)
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/connexion/admin/login",data).then(function(result)
            {
                var data=result.data;

                resolve(data);
                if(data.reponse!==undefined && data.reponse.status==="success")
                {
                    commit("setAdmin",data.reponse.data);
                }
            });
        });
    }
    ,
    logOut({state,commit})
    {
        commit("setAdmin",{});
    },
    getAdmins({state,commit},data)
    {
        return new Promise(function(resolve)
        {
           axios.post(state.baseURL+"/admin/users/all",data).then(function(result)
           {
               var data=result.data;

               resolve(data);

               commit("setAdmins",data.admins);
           });
        });

    },
    addNewInfo({state},data)
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/diligences/ajouterinformation",data).then(function(result)
            {
               var data=result.data;

               resolve(data);
            });
        });
    },
    genererCertificat({state},data)
    {
        return new Promise(function(resolve)
        {
            axios.post(state.baseURL+"/admin/diligences/certificat",data).then(function(result)
            {
               var data=result.data;

               resolve(data);
            });
        });
    }

};
export default actions;
//export default actions;                                                                                                                                                                                                                                                                                                                                                                                                                                          
