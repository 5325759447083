<template>
  <div id="app">
    <x-layout />
  </div>
</template>

<script>
import XLayout from "@/components/layouts/layout";
export default {
  components: {
    XLayout,
  },
};
</script>
