<template>
  <div class="dashboard-ecommerce">
    <div class="container-fluid dashboard-content">
      <x-loading v-if="isLoading" />
      <!-- ============================================================== -->
      <!-- pageheader  -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="page-header">
            <h2 class="pageheader-title">TABLEAU DE BORD</h2>
            <p class="pageheader-text"></p>
            <div class="page-breadcrumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#" class="breadcrumb-link">ACCUEIL</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    TABLEAU DE BORD
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- end pageheader  -->
      <!-- ============================================================== -->
      <div class="ecommerce-widget">
        <div class="row">
          <!-- ============================================================== -->
          <!-- sales  -->
          <!-- ============================================================== -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card border-3 border-top border-top-dark">
              <div class="card-body">
                <h5 class="text-muted">CLIENTS INSCRITS</h5>
                <div class="metric-value d-inline-block">
                  <h1 class="mb-1">20</h1>
                </div>
                <div
                  class="metric-label d-inline-block float-right text-success font-weight-bold"
                >
                  <span class="icon-circle-small icon-box-xs text-dark bg-dark-light"
                    ><i class="fa fa-fw fa-arrow-up"></i></span
                  ><span class="ml-1"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- end sales  -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- new customer  -->
          <!-- ============================================================== -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card border-3 border-top border-top-danger">
              <div class="card-body">
                <h5 class="text-muted">NOUVELLES DILIGENCES</h5>
                <div class="metric-value d-inline-block">
                  <h1 class="mb-1">12</h1>
                </div>
                <div
                  class="metric-label d-inline-block float-right text-success font-weight-bold"
                >
                  <span class="icon-circle-small icon-box-xs text-danger bg-danger-light"
                    ><i class="fa fa-fw fa-pie-chart"></i></span
                  ><span class="ml-1"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- end new customer  -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- visitor  -->
          <!-- ============================================================== -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card border-3 border-top border-top-primary">
              <div class="card-body">
                <h5 class="text-muted">VISTEURS</h5>
                <div class="metric-value d-inline-block">
                  <h1 class="mb-1">130</h1>
                </div>
                <div
                  class="metric-label d-inline-block float-right text-success font-weight-bold"
                >
                  <span
                    class="icon-circle-small icon-box-xs text-primary bg-primary-light"
                    ><i class="fa fa-fw fa-users"></i></span
                  ><span class="ml-1"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- end visitor  -->
          <!-- ============================================================== -->
          <!-- ============================================================== -->
          <!-- total orders  -->
          <!-- ============================================================== -->
          <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12">
            <div class="card border-3 border-top border-top-success">
              <div class="card-body">
                <h5 class="text-muted">DILIGENCES EN COURS</h5>
                <div class="metric-value d-inline-block">
                  <h1 class="mb-1">134</h1>
                </div>
                <div
                  class="metric-label d-inline-block float-right text-danger font-weight-bold"
                >
                  <span
                    class="icon-circle-small icon-box-xs text-success bg-danger-light bg-success-light"
                    ><i class="fa fa-fw fa-dot-circle"></i></span
                  ><span class="ml-1"></span>
                </div>
              </div>
            </div>
          </div>
          <!-- ============================================================== -->
          <!-- end total orders  -->
          <!-- ============================================================== -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Dashboard",

  data() {
    return {
      isLoading: false,
    };
  },

  mounted() {

  },

  computed: {

  },

  methods: {

  },
};
</script>
