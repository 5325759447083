<template>
  <div class="nav-left-sidebar sidebar-dark">
    <div class="menu-list">
      <nav class="navbar navbar-expand-lg navbar-light">
        <a class="d-xl-none d-lg-none" href="#">Dashboard</a>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarNav"
          aria-controls="navbarNav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
          <ul class="navbar-nav flex-column">
            <li class="nav-divider m-t-20">Menu</li>
            <li class="nav-item">
              <router-link
                tag="a"
                :to="{ name: 'dashboard' }"
                :class="`nav-link ${currentRoute.includes('home') ? 'active' : ''}`"
                ><i class="fa fa-fw fa-th"></i>TABLEAU DE BORD
              </router-link>
            </li>
            <li class="nav-item">
              <router-link
                :to="{ name: 'diligence-page' }"
                tag="a"
                :class="`nav-link ${currentRoute.includes('diligences') ? 'active' : ''}`"
                ><i class="fa fa-fw fa-clipboard-list"></i>DILIGENCES</router-link
              >
            </li>

            <li class="nav-item">
              <router-link
                :to="{ name: 'user-page' }"
                tag="a"
                :class="`nav-link ${currentRoute.includes('users') ? 'active' : ''}`"
                ><i class="fa fa-fw fa-users"></i>UTILISATEURS</router-link
              >
            </li>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      currentRoute: "",
    };
  },
  mounted() {
    setInterval(() => {
      //this.currentRoute = this.$route.path.replaceAll("/", "");
    }, 100);
  },

  computed: {

  },

  methods: {
    logout() {
      this.$swal({
        buttonsStyling: true,
        title: "Déconnexion !",
        text: `Etes-vous sûr de vouloir vous déconnecter de votre compte ?`,
        icon: "question",
        showCancelButton: true,
        confirmButtonText: "Oui",
        cancelButtonText: "Non",
      }).then((result) => {
        if (result.value) {
          this.$store.dispatch("logout");
          this.$router.go(this.$router.push({ name: "login" }));
        } else return;
      });
    },
  },
};
</script>
