var domain="http://127.0.0.1/edge_verified-backend";
if(location.href.includes(".com"))
{
    if(location.protocol!="https:")
    {
        location.protocol="https:";
    }
    if(location.href.includes("edgeverifed.com"))
    {
        domain="https://back-system.edgeverifed.com"; /** live server */
    }
    else
    {
        domain="https://edge-verified.rtgroup-rdc.com"; /** test server */
    }
}

const states = {
    baseURL: domain,
    diligences:[],
    admin:{},
    diligenceDetails:{},
    admins:[]
};
export default states;
//export default states;               
