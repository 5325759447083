import cacheStore from "../cacheStore";
const mutations = {
    setDiligences(state,data)
    {
        state.diligences=data;
    },
    setDiligenceDetails(state,data)
    {
        state.diligenceDetails=data;
    },
    setAdmins(state,data)
    {
        state.admins=data;
    },
    setAdmin(state,data)
    {
        cacheStore.save("admin",data); /**save dans le cache */
        state.admin=data;
    }

};
export default mutations;
