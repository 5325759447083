<template>
  <div class="dashboard-ecommerce">
    <div class="container-fluid dashboard-content">
      <x-loading v-if="isLoading" />
      <!-- ============================================================== -->
      <!-- pageheader  -->
      <!-- ============================================================== -->
      <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div class="page-header">
            <h2 class="pageheader-title">DILIGENCES</h2>
            <p class="pageheader-text"></p>
            <div class="page-breadcrumb">
              <nav aria-label="breadcrumb">
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="#/home" class="breadcrumb-link">TABLEAU DE BORD</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">DILIGENCES</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <!-- ============================================================== -->
      <!-- end pageheader  -->
      <!-- ============================================================== -->
      <div>
        <div class="container-fluid">
          <div class="main-content container-fluid p-0">
            <div class="email-filters">
              <div class="email-filters-left">
                <div class="btn-group">
                  <button
                    class="btn btn-light dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                  >
                    Filtrer par <span class="caret"></span>
                  </button>
                  <div class="dropdown-menu" role="menu">
                    <a class="dropdown-item text-muted" href="#">En cours</a
                    ><a class="dropdown-item text-success" href="#">Clotûrées</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item text-danger" href="#">Rejetées</a>
                  </div>
                </div>
              </div>
            </div>
            <div class="email-list">
              <router-link
                class="email-list-item email-list-item--unread"
                v-for="diligence in diligences"
                :key="diligence.diligence_id"
                :to="{ name: 'diligence-detail' ,params:{id:diligence.diligence_id}}"
              >
                <div class="email-list-actions">
                  <span><i class="fas fa-th"></i></span>
                </div>
                <div  class="email-list-detail">
                  <span  class="date float-right"
                    ><span class="icon">
                      <span class="badge badge-warning m-2"> {{diligence.diligence_status}}</span
                      ><i
                        class="fas fa-calendar text-muted"
                        style="font-size: 14px"
                      ></i> </span
                    >{{diligence.date_enregistrement}}
                  </span>


                  <h2 class="from"><span class="fa fa-user-circle"></span> {{diligence.client}}</h2>
                  <p class="msg">
                    <span class="text-primary">Type : {{diligence.diligence_type}}</span>
                  </p>
                </div>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    data()
    {
      return {
        isLoading:true
      }
    }
    ,computed:{
      diligences()
      {
        return this.$store.state.diligences;
      },
      admin()
      {
        return this.$store.getters.getAdmin;
      }
    },
    mounted()
    {
      var formData=new FormData();
      formData.append("admin_id",this.admin.admin_id);

      this.$store.dispatch("getDiligences",formData).then(()=>{
        this.isLoading=false;
      });
    }
  }
</script>
