<template>
  <!-- start header -->
  <div class="dashboard-header">
    <nav class="navbar navbar-expand-lg bg-white fixed-top">
      <a class="navbar-brand" href="index.html"
        ><img src="assets/images/logo.png" class="header-logo"
      /></a>
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav ml-auto navbar-right-top">
          <li class="nav-item">
            <div id="custom-search" class="top-search-bar">
              <input class="form-control" type="text" placeholder="Search.." />
            </div>
          </li>
          <li class="nav-item dropdown notification">
            <a
              class="nav-link nav-icons"
              href="#"
              id="navbarDropdownMenuLink1"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><i class="fas fa-fw fa-bell"></i> <span class="indicator"></span
            ></a>
            <ul class="dropdown-menu dropdown-menu-right notification-dropdown">
              <li>
                <div class="notification-title">Notifications</div>
                <div
                  class="slimScrollDiv"
                  style="position: relative; overflow: hidden; width: auto; height: 250px"
                >
                  <div
                    class="notification-list"
                    style="overflow: hidden; width: auto; height: 250px"
                  >
                    <div class="list-group">
                      <a
                        href="#"
                        class="list-group-item list-group-item-action active"
                        v-for="i in 4"
                        :key="i"
                      >
                        <div class="notification-info">
                          <div class="notification-list-user-img">
                            <img
                              src="assets/images/avatar-2.jpg"
                              alt=""
                              class="user-avatar-md rounded-circle"
                            />
                          </div>
                          <div class="notification-list-user-block">
                            <span class="notification-list-user-name"
                              >Lorem ipsum dolor sit amet</span
                            >Lorem ipsum dolor sit amet Lorem ipsum dolor, sit amet
                            consectetur adipisicing elit..
                            <div class="notification-date">20 oct.</div>
                          </div>
                        </div>
                      </a>
                    </div>
                  </div>
                  <div
                    class="slimScrollBar"
                    style="
                      background: rgb(0, 0, 0) none repeat scroll 0% 0%;
                      width: 7px;
                      position: absolute;
                      top: 0px;
                      opacity: 0.4;
                      display: block;
                      border-radius: 7px;
                      z-index: 99;
                      right: 1px;
                    "
                  ></div>
                  <div
                    class="slimScrollRail"
                    style="
                      width: 7px;
                      height: 100%;
                      position: absolute;
                      top: 0px;
                      display: none;
                      border-radius: 7px;
                      background: rgb(51, 51, 51) none repeat scroll 0% 0%;
                      opacity: 0.2;
                      z-index: 90;
                      right: 1px;
                    "
                  ></div>
                </div>
              </li>
              <li>
                <div class="list-footer"><a href="#">Voir plus...</a></div>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown connection">
            <a
              class="nav-link"
              href="#"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fas fa-fw fa-th"></i>
            </a>
            <ul class="dropdown-menu dropdown-menu-right connection-dropdown">
              <li class="connection-list">
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/github.png" alt="" />
                      <span>Diligence 01</span></a
                    >
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/dribbble.png" alt="" />
                      <span>Diligence 02</span></a
                    >
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/dropbox.png" alt="" />
                      <span>Diligence 03</span></a
                    >
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/bitbucket.png" alt="" />
                      <span>Diligence 04</span></a
                    >
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/mail_chimp.png" alt="" /><span
                        >Diligence 05</span
                      ></a
                    >
                  </div>
                  <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                    <a href="#" class="connection-item"
                      ><img src="assets/images/slack.png" alt="" />
                      <span>Diligence 06</span></a
                    >
                  </div>
                </div>
              </li>
              <li>
                <div class="conntection-footer"><a href="#">More</a></div>
              </li>
            </ul>
          </li>
          <li class="nav-item dropdown nav-user">
            <a
              class="nav-link nav-user-img"
              href="#"
              id="navbarDropdownMenuLink2"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              ><img
                src="assets/images/avatar-1.jpg"
                alt=""
                class="user-avatar-md rounded-circle"
            /></a>
            <div
              class="dropdown-menu dropdown-menu-right nav-user-dropdown"
              aria-labelledby="navbarDropdownMenuLink2"
            >
              <div class="nav-user-info bg-dark">
                <h5 class="mb-0 text-white nav-user-name">{{admin.nom}}</h5>
                <span class="status"></span><span class="ml-2">admin</span>
              </div>

              <a class="dropdown-item" href="#" @click.prevent="logOut"
                ><i class="fas fa-power-off mr-2"></i>Deconnexion</a
              >
            </div>
          </li>
        </ul>
      </div>
    </nav>
  </div>

  <!-- end header -->
</template>

<script>
export default {
  name: "NavHeader",

  data() {
    return {
      isloading: false,
    };
  },
  mounted() {
  },

  computed: {
    admin() {
      return this.$store.getters.getAdmin;
    },
  },

  methods: {
    logOut()
    {
      this.$store.dispatch("logOut");
      this.$router.replace({name:"login"});
    },
    //autocomplete search
    search(input) {
      return new Promise((resolve) => {
        if (input.length <= 1) {
          return resolve([]);
        }
        let formData = new FormData();
        formData.append("search_term", input);
        formData.append("admin_id", "1");
        this.$axios.post("operations/beneficiaires/recherche", formData).then((res) => {
          if (res.data == []) {
            return;
          }
          resolve(res.data.beneficiaires);
        });
      });
    },
  },
};
</script>

<style>
.brand-text {
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;

  text-shadow: rgba(12, 11, 11, 0.5) 0px 3px 3px;
  font-weight: bold;
  letter-spacing: 1px;
}

.sub-logo {
  font-size: 20px;
  font-weight: bold;
  text-transform: capitalize;
  letter-spacing: 2px;
  color: rgb(2, 17, 88);
}
</style>
