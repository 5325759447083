import cacheStore from "../cacheStore";
const getters = {
    getAdmins: state => state.admins,
    getAdmin(state)
    {
        var admin=state.admin;
        if(Object.keys(admin).length<1)
        {
            /** recuperer du cache */
            admin=cacheStore.get("admin");
        }

        return admin;

    }

};

export default getters;
