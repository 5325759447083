<template>
  <div>
    <x-loading v-if="isLoading" />
    <div class="splash-container">
      <div class="card">
        <div class="card-header text-center">
          <img class="logo-img" src="assets/images/logo.png" alt="logo" />
        </div>
        <div class="card-body">
          <div class="form-group">
            <input
              class="form-control form-control-lg border-bottom-dark"
              id="username"
              type="text"
              placeholder="Email ou Téléphone"
              autocomplete="off"
              v-model="user.identifiant"
            />
          </div>
          <div class="form-group">
            <input
              class="form-control form-control-lg border-bottom-dark"
              id="password"
              type="password"
              placeholder="Mot de passe"
              v-model="user.pwd"
            />
          </div>

          <button type="submit" class="btn btn-dark btn-lg btn-block" @click="login">
            CONNECTER
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",

  data() {
    return {
      user: {
        identifiant: "",
        pwd: "",
      },
      isLoading: false,
    };
  },

  methods: {
    goForward() {
      this.$router.go(this.$router.push({ name: "home" }));
    },
    login() {
      if (this.user.identifiant === "") {
        this.$swal({
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          icon: "warning",
          title: "Identifiant(email ou n° de téléphone) requis !",
        });
        return;
      }
      if (this.user.pwd === "") {
        this.$swal({
          toast: true,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: false,
          icon: "warning",
          title: "Mot de passe requis !",
        });
        return;
      }
      let formData = new FormData();
      formData.append("identifiant", this.user.identifiant);
      formData.append("pass", this.user.pwd);
      this.isLoading = true;

      this.$store.dispatch("login",formData).then((res)=>{
        this.isLoading=false;
        if(res.reponse!==undefined && res.reponse.status==="success")
        {
          //this.$router.replace({ name: "home" })
          this.$router.go(this.$router.push({ name: "home" }));

        }
        else
        {
          this.$swal({
            toast: true,
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: false,
            icon: "error",
            title: "Identifiant ou Mot de passe incorrect !",
          });
        }
      });

    },
  },
};
</script>

<style>
.splash-container {
  position: absolute;
  top: 30%;
  left: 50%;
  bottom: 50%;
  transform: translateX(-55%);
}
</style>
